
  import { defineComponent, onBeforeMount, ref, reactive, toRefs } from 'vue';
  import { vuex } from '@/store';
  import { useRouter, useRoute } from 'vue-router';
  import { useToast } from "vue-toastification";
  import dayjs from 'dayjs';

  import AppBreadCrumbs from '@/components/AppBreadCrumbs.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import ChildForm from '@/views/children/components/ChildForm.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IChild, IChildForm, ISchool, ISchoolRes } from '@/types';
  import { routesNames } from '@/router';
  import { childrenService, schoolService } from '@/services';
  import { getChangedData } from '@/core/helper-functions';
  import { SCHOOLS_PARAMS } from './api-params';

  export default defineComponent({
    name: 'UpdateChild',

    components: { ChildForm, AppModal, AppButton, AppBreadCrumbs, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const router = useRouter();
      const route = useRoute();
      const state: any = reactive({
        childFormData: {} as IChildForm,
        originalChildFormData: {} as IChildForm,
        schools: [] as ISchool[]
      });
      const disabledHandleChanges = ref<boolean>(false);

      const { childFormData, originalChildFormData } = toRefs(state);
      const isOpenConfirmRemoveModal = ref<boolean>(false);

      function dataForSubmit(payload: IChild) {
        // Set school to null if user entered other school name
        if (payload.otherSchoolName) {
          originalChildFormData.value.school = null;
        }

        // Format grade property to appropriate with child data
        originalChildFormData.value.grade = originalChildFormData.value.grade.value;

        return getChangedData(originalChildFormData.value, payload);
      }

      async function onSubmit(res: IChild) {
        const formatedData = dataForSubmit(res);

        vuex.setLoading(true);
        await childrenService.updateChild(childFormData.value.id, formatedData)
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Child information has been updated');
            router.push({ name: routesNames.children, query: { id: childFormData.value.id } });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      function handleConfirmRemove() {
        isOpenConfirmRemoveModal.value = true;
      }

      async function confirmRemove() {
        if (childFormData.value.id) {
          vuex.setLoading(true);

          return childrenService.removeChild(childFormData.value.id)
            .then(() => {
              disabledHandleChanges.value = true;
              toast.success('Child has been removed');
              const redirectPageName = Number(route.params.childrenAmount) > 1 
              ? routesNames.children 
              : routesNames.root;
              router.push({ name: redirectPageName });
            })
            .catch(({ response }: any) => {
              const { data } = response;
              toast.error(data.message);
            })
            .finally(() => vuex.setLoading(false));
        }
      }

      function cancelRemoving() {
        isOpenConfirmRemoveModal.value = false;
      }

      function handleChildResponse(res: IChild) {

        const formatedChildData: IChildForm = {
          ...res,
          school: !res.school ? { name: 'Other' } : res.school, // Set "Other" to school if not provided
          grade: { value: res.grade },
          schoolId: res.schoolId,
          day: dayjs(res.birthMonthDate).format('D'),
          month:  { name: dayjs(res.birthMonthDate).format('MMMM') , value: dayjs(res.birthMonthDate).format('MM') },
          year: dayjs(res.birthMonthDate).format('YYYY'),
        };

        childFormData.value = { ...formatedChildData };
        originalChildFormData.value = { ...formatedChildData };
      }

      async function fetchChild() {
        return childrenService.fetchChild(route.query.id as string)
          .then((res: IChild) => handleChildResponse(res))
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          });
      }

      async function fetchSchools() {
        return schoolService.fetchSchools(SCHOOLS_PARAMS)
          .then((res: ISchoolRes) => { state.schools = [...res.data, { name: 'Other' }]; })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          });
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        return Promise.all([
          fetchChild(),
          fetchSchools()
        ])
          .catch(() => router.push({ name: routesNames.children }))
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        if (!route.query.id) router.push({ name: routesNames.children });
        else { await fetchInitData(); }
      });

      return {
        state,
        childFormData,
        isOpenConfirmRemoveModal,
        disabledHandleChanges,

        cancelRemoving,
        onSubmit,
        handleConfirmRemove,
        confirmRemove
      };
    }
  });
